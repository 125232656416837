<script setup>
import {ref, onMounted, computed, watch, defineAsyncComponent} from 'vue';
import Image from "~/components/atoms/Image.vue";
import Carousel from "~/components/molecules/Carousel.vue";
import Timer from "~/components/molecules/Timer.vue";

const props = defineProps(['full_images', 'previews', 'timer_date', 'hoveredImage']);

const LightBox = defineAsyncComponent({
  loader: () => import("~/components/organisms/LightBox.vue"),
});

const mainImageRef = ref(null);
const currentIndex = ref(0);
const firstSwipe = ref(false);
const previewsContainer = ref(null);
const absolutePreviews = ref(null);
const indexToLightBox = ref(null);

const device = useDevice();

const margin = computed(() => {
  return (mainImageRef.value?.getBoundingClientRect()?.width * currentIndex.value * -1) + 'px';
});

const previewStep = computed(() => {
  return absolutePreviews.value?.children[0]?.clientWidth || 0;
});

const selectPreview = (index) => {
  currentIndex.value = index;
  scrollToActivePreview();
};

const scrollToActivePreview = () => {
  if (!previewsContainer.value) return;
  let chunk = Math.round(previewsContainer.value.clientHeight / previewStep.value);
  let chunkMobile = Math.round(previewsContainer.value.clientWidth / (previewStep.value + 10));
  let currentChunk = parseInt(currentIndex.value / chunk);
  let currentChunkMobile = parseInt(currentIndex.value / chunkMobile);

  if (device.isDesktop) {
    previewsContainer.value.scrollTop = ((chunk * currentChunk) * previewStep.value) + (10 * (chunk * currentChunk));
  } else {
    previewsContainer.value.scrollLeft = ((chunkMobile * currentChunkMobile) * previewStep.value) + (12 * (chunkMobile * currentChunkMobile));
  }
};

const setMainImageRef = (el) => {
  if (!mainImageRef.value) {
    mainImageRef.value = el;
  }
};

const left = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
    scrollToActivePreview();
  }
};

const right = () => {
  firstSwipe.value = true;
  if (currentIndex.value < props.full_images.length - 1) {
    currentIndex.value++;
    scrollToActivePreview();
  } else {
    currentIndex.value = 0;
    scrollToActivePreview();
  }
};

const carouselChange = (event) => {
  currentIndex.value = event.currentSlideIndex;
  scrollToActivePreview();
  selectPreview(event.currentSlideIndex);
};

onMounted(() => {
  scrollToActivePreview();
});

</script>

<template>
  <div class="gallery row" id="product-gallery">
    <div v-if="timer_date" class="product-timer">
      <div class="timer-part no-flex">
        <Timer :date="timer_date"/>
      </div>
      <div class="timer-part no-flex">KAMPANJPRIS</div>
    </div>
    <div class="previews" ref="previewsContainer">
      <div class="absolute-previews full-width" ref="absolutePreviews">
        <div v-for="(preview,index) in props.previews" class="preview full-width aspect-1"
             :class="{active:currentIndex===index}">
          <Image @click="selectPreview(index)" :src="preview.url"/>
        </div>
        <div class="cross" :style="{left:(previewStep * currentIndex)+(8 * currentIndex)+'px'}"></div>
      </div>
    </div>
    <div class="full-image">
      <div v-if="currentIndex > 0" @click="left" class="swiper-btn left hidden-mobile hidden">
        <img class="white" src="/images/moves/arrow.png"/>
      </div>

      <div class="image-block row">
        <transition name="fade">
          <div class="hover-image" v-if="hoveredImage">
            <Image :src="hoveredImage"/>
          </div>
        </transition>
        <Carousel :modelValue="currentIndex" :items-to-show="1" :no-arrows="true" :items-to-show-mobile="1"
                  class="full-width"
                  :infinity="true"
                  @change="carouselChange($event)"
                  :items-to-scroll="1" :items="props.full_images">
          <template #default="{item,index}">
            <template v-if="item.origin?.includes('mp4')">
              <video @click="indexToLightBox=index" class="lg-video-object lg-html5 video-js vjs-default-skin"
                     autoplay loop muted
                     playsinline width="100%">
                <source
                    :src="item.origin"
                    type="video/mp4"/>
              </video>
            </template>
            <template v-else>
              <div style="width: 100%; aspect-ratio: 1">
                <Image @click="indexToLightBox=index" :src="item.url"/>
              </div>
            </template>
          </template>
        </Carousel>
      </div>
      <div class="counter">
        <span id="gallery-counter">{{ currentIndex + 1 }}</span> <span>/ {{ props?.full_images.length }}</span>
      </div>
      <div v-if="props?.full_images.length > 1" @click="right" class="swiper-btn right hidden-mobile">
        <img class="white" src="/images/moves/arrow.png" alt="Swipe to right">
      </div>
    </div>
  </div>

  <LightBox @close="indexToLightBox=null" v-if="indexToLightBox!==null" :index="indexToLightBox"
            :items="props?.full_images.map(item=>({src:item.origin ?? item.url, thumb:item.url}))"/>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/variables.scss";

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  transition: opacity 0.3s ease-in-out;
  justify-content: center;
  align-items: center;

  img {
    transition: opacity 0.3s ease-in-out;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

.gallery {
  gap: 10px;
  width: 100%;
  align-items: normal;
  height: 100%;

  .previews {
    position: relative;
    overflow: scroll;
    scroll-behavior: smooth;
    justify-content: flex-start;
    flex-grow: 1;

    @include smd {
      min-height: 91px;
      overflow-y: hidden;
    }

    @include mobile {
      padding-left: 16px;
    }

    .preview {
      position: relative;
      cursor: pointer;

      &.active {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid black;

          @include smd {
            border: unset;
          }
        }
      }
    }


    &::-webkit-scrollbar {
      display: none;
    }

    @include smd {
      margin-left: 0;
      overflow: scroll;
      scroll-behavior: smooth;
    }

    .absolute-previews {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: absolute;

      @include smd {
        position: relative;
        flex-direction: row;
        max-height: 82px;
        gap: 8px
      }

      & > div:not(.cross) {
        @include smd {
          max-width: 80px;
          min-width: 80px;
          min-height: 80px;
          max-height: 80px;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .cross {
    display: none;
    content: "";
    position: absolute;
    z-index: 9999;
    transition: 0.3s all ease-in-out;

    @include smd {
      display: block;
      left: 0;
      background: black;
      height: 1px;
      width: 80px;
      bottom: -4px;
      z-index: 999;
    }
  }

  .full-image {
    min-width: 83%;
    width: 83%;
    position: relative;

    .alternative-preview {
      position: absolute;
      left: 0;
      top: 0;
      height: auto;
      display: none;
      z-index: 9;
      width: 101%;
      transition: 0.2s opacity ease-in-out;
      opacity: 0;

      &.active {
        display: block;
      }

      &.show-op {
        opacity: 1;
      }
    }

    @include smd {
      width: 100%;
      min-height: 350px;
    }

    .image-block {
      width: 100%;
      max-width: 800px;
      overflow: hidden;
      display: flex;
      position: relative;
      cursor: pointer;

      @include smd {
        max-width: unset;
      }

      a {
        min-width: 100%;
        transition: 0.4s all ease-in-out;

        img {
          width: 100%;
        }
      }
    }

    .counter {
      position: absolute;
      bottom: 40px;
      right: 40px;
      background: #222222;
      color: white;
      font-size: 11px;
      font-weight: 600;
      width: fit-content;
      height: 19px;
      display: unset;
      padding: 2px 5px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      @include smd {
        right: 15px;
        bottom: 18px;
      }
    }
  }

  @include smd {
    flex-direction: column-reverse;
    margin: 0;
    min-height: 400px;
  }

  .swiper-btn {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 65px;
    height: 65px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 32px 48px rgba(82, 82, 82, 0.08);
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    z-index: 9;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &:hover {
      background: rgb(249 249 249 / 90%);
      color: white;

      @include smd {
        background: rgba(255, 255, 255, 0.9);
        color: black;
      }
    }

    img {
      height: 30%;
      width: auto;
    }

    &.left {
      left: -30px;

      img {
        transform: rotate(180deg);
      }
    }

    &.right {
      left: unset;
      right: -30px;
      float: unset;
    }

    @include smd {
      display: none;
    }
  }

  .product-timer {
    position: absolute;
    z-index: 999;
    bottom: 36px;
    left: 23px;

    @include smd {
      transform: scale(.58);
      bottom: 80px;
      left: -50px;
    }

    &:deep(.timer) {
      position: static;

      .counter {
        border: unset;
        display: block;
        padding-top: 8px;

        span, .meaning {
          color: white;
        }
      }
    }
  }

  .timer-part {
    background: #222;
    color: $white;
    width: fit-content;
    font-size: 32px;
    font-weight: 600;
    padding: 0 8px;

    @include smd {
      margin-top: -1px;
    }
  }
}
</style>
